import pluralize from 'pluralize'
import { t } from 'services/i18n'

import {
  asianIdeographRegex,
  LISTING_DESCRIPTION_REGEX
} from 'constants/listing_builder_v2'
import {
  DEFAULT_LAST_FEATURE_INDEX,
  EXCLUDE_ARTICLES_AND_PREPOSITIONS,
  FEATURE_KEYS
} from 'constants/listing_builder/listing_builder'
import { EMPTY_MARKER } from 'constants/table'
import { lowercase } from './strings'

import { humanizedDateFromEpoch } from './formatters'
import { sortBy } from './arrays'

const EXPECTED_FEATURES = 5
const EXPECTED_IMAGES = 7
const MAX_IMAGES = 9

const isHighResImage = image => {
  const { Width, Height } = image.HiResImage
  return Width._ >= 1000 && Height._ >= 1000
}

const gradeProductDescription = (
  { description, enhancedContent = false },
  { lowerBound, upperBound }
) => {
  const length = description?.length || 0
  return enhancedContent || (length >= lowerBound && length <= upperBound)
    ? 1
    : 0
}

const gradeProductTitle = (productTitle, { lowerBound, upperBound }) => {
  const length = productTitle?.length || 0
  if (length >= lowerBound && length <= upperBound) {
    return 2
  }
  if (length > 0 && length <= upperBound) {
    return 1
  }
  return 0
}

const calcProductFeatures = (features, { lowerBound, upperBound }) => {
  let nonEmptyFeatures = 0
  let featuresWithinBounds = 0

  features.forEach(feature => {
    const length = feature?.length || 0
    if (length) {
      nonEmptyFeatures += 1
    }
    if (length >= lowerBound && length <= upperBound) {
      featuresWithinBounds += 1
    }
  })

  return { nonEmptyFeatures, featuresWithinBounds }
}

const gradeProductFeatures = (features, { lowerBound, upperBound }) => {
  const { nonEmptyFeatures, featuresWithinBounds } = calcProductFeatures(
    features,
    { lowerBound, upperBound }
  )
  if (featuresWithinBounds >= EXPECTED_FEATURES) {
    return 3
  }
  if (nonEmptyFeatures >= EXPECTED_FEATURES) {
    return 2
  }
  if (featuresWithinBounds) {
    return 1
  }
  return 0
}

const gradeSearchTerms = (searchTerms, { lowerBound, upperBound }) => {
  const length = searchTerms?.length || 0
  return length >= lowerBound && length <= upperBound ? 1 : 0
}

const calcAdditionalImages = images => {
  // harvest (sp-api) usally returns the same image 3 times
  // but this is not always the case. The thumbnail image seem to always
  // be present and contain _SL75_ in the URL. So we use to calculate the number of images
  const imagesCount = images?.filter(image => {
    return image.HiResImage.URL.includes('_SL75_')
  }).length

  const highResImagesCount = images?.filter(isHighResImage)?.length || 0

  return { imagesCount, highResImagesCount }
}

const gradeAdditionalImages = images => {
  const { imagesCount, highResImagesCount } = calcAdditionalImages(images)

  if (!imagesCount || imagesCount > MAX_IMAGES) {
    return 0
  }
  if (imagesCount >= EXPECTED_IMAGES && imagesCount === highResImagesCount) {
    return 2
  }
  if (imagesCount >= EXPECTED_IMAGES || highResImagesCount) {
    return 1
  }
  return 0
}

/*
Score Range    Score (What the user sees)
90-100          Optimized Listing
70-89           Good Listing
50-69           Fair Listing
0-49            Needs Improvement
*/
export const convertRawScore = score => {
  if (typeof score !== 'number') {
    return EMPTY_MARKER
  }

  if (score >= 90) {
    return t(
      'listings:listingsBuilderV2.ListingsList.STATUS_LABEL_MAP.fully_optimized',
      'Fully Optimized'
    )
  }

  if (score >= 70) {
    return t(
      'listings:listingsBuilderV2.ListingsList.STATUS_LABEL_MAP.good_listing',
      'Good Listing'
    )
  }

  if (score >= 50) {
    return t(
      'listings:listingsBuilderV2.ListingsList.STATUS_LABEL_MAP.fair_listing',
      'Fair Listing'
    )
  }

  return t(
    'listings:listingsBuilderV2.ListingsList.STATUS_LABEL_MAP.needs_improvement',
    'Needs Improvement'
  )
}

const formatListingAttribute = data => {
  // fix quotes to double quotes inside strings
  return `"${data ? data.replace(/"/g, '""') : ''}"`
}

const formatListingDate = date => {
  return humanizedDateFromEpoch(new Date(date).getTime(), 'MM/DD/YYYY')
}

export const formatListingForExport = (listing, isV2) => {
  if (!listing) {
    return ''
  }

  const columns = isV2
    ? [
        formatListingAttribute(listing.parent_asin),
        formatListingAttribute(listing.asin),
        formatListingAttribute(listing.sku),
        formatListingAttribute(listing.listing_title),
        formatListingAttribute(listing.status),
        convertRawScore(listing.score * 10),
        formatListingAttribute(listing.product_title),
        formatListingAttribute(listing.feature_one),
        formatListingAttribute(listing.feature_two),
        formatListingAttribute(listing.feature_three),
        formatListingAttribute(listing.feature_four),
        formatListingAttribute(listing.feature_five),
        formatListingAttribute(listing.description),
        formatListingAttribute(listing.search_terms),
        formatListingDate(listing.updated_at)
      ]
    : [
        formatListingAttribute(listing.asin),
        formatListingAttribute(listing.sku),
        formatListingAttribute(listing.listing_title),
        formatListingAttribute(listing.status),
        convertRawScore(listing.score * 10),
        formatListingAttribute(listing.product_title),
        formatListingAttribute(listing.feature_one),
        formatListingAttribute(listing.feature_two),
        formatListingAttribute(listing.feature_three),
        formatListingAttribute(listing.feature_four),
        formatListingAttribute(listing.feature_five),
        formatListingAttribute(listing.description),
        formatListingAttribute(listing.search_terms || listing.searchTerms),
        formatListingDate(listing.created_at),
        formatListingDate(listing.updated_at)
      ]

  return columns.join(',')
}

/**
 * Clean up the HTML tags in the description
 * partly this is to conform to amazon's rules
 * partly it's to save on character count because froala isn't super efficient in it's use of tags
 * @param {string} html
 */
export const stripDescriptionTags = (html = '') => {
  const regex = new RegExp(LISTING_DESCRIPTION_REGEX, 'gi')
  return (
    html
      // froala styling
      .replace(regex, '')
      // <strong> to <b>
      .replace(/<strong.*?>/g, '<b>')
      .replace(/<\/strong>/g, '</b>')
      // <em> to <i>
      .replace(/<em.*?>/g, '<i>')
      .replace(/<\/em>/g, '<i>')
      // <h1> <h2> etc to <b>
      .replace(/<h\d+.*?>/g, '<b>')
      .replace(/<\/h\d+>/g, '</b>')
      // remove paragraphs
      .replace(/<p.*?>/g, '')
      // end paragraphs to new lines
      .replace(/<\/p>/g, '</br>')
      .replace(/&nbsp;/g, ' ') // spaces to erm spaces.. just less chars
      .replace(/<div.*?>/g, '')
      .replace(/<\/div>/g, '')
      .replace(/<\/?span.*?>/g, '')
      .replace(/<a.*?>/g, '')
      .replace(/<\/a>/g, '')
      .replace(/<\/br>$/, '')
  ) // trim trailing new line
}

export const decodeHtmlTags = input =>
  input
    ?.replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&quot;/g, '"')

export const splitKeywordsByVolume = keywords => {
  const top3rd = Math.ceil(keywords.length / 3)
  const middle3rd = Math.ceil((2 * keywords.length) / 3)

  const topKeywords = keywords.slice(0, top3rd)
  const middleKeywords = keywords.slice(top3rd, middle3rd)
  const bottomKeywords = keywords.slice(middle3rd, keywords.length)
  return {
    topKeywords,
    middleKeywords,
    bottomKeywords
  }
}

const searchVolume = keyword =>
  keyword.exactSearchVolume ? Number.parseInt(keyword.exactSearchVolume, 10) : 0

export const sumKeywordsVolume = keywords =>
  keywords.reduce((sum, keyword) => sum + searchVolume(keyword), 0)

export const sumUsedKeywordsSearchVolume = (
  usedKeywordsData,
  keywordsAndPhrases
) => {
  let volume = 0
  usedKeywordsData.globalUsedKeywordIds.forEach(id => {
    volume += searchVolume(keywordsAndPhrases[id])
  })
  return volume
}

export const PRODUCT_TITLE = 'product_title'
export const FEATURES = 'features'
export const DESCRIPTION = 'description'
export const SEARCH_TERMS = 'search_terms'
export const ADDITIONAL_IMAGES = 'additional_images'
export const KEYWORDS_USED = 'keywords_used'

const SEARCH_VOLUME_USED_THRESHOLD = 70

const searchVolumeUsedPercentage = ({
  usedKeywordsSearchVolume,
  totalSearchVolume
}) =>
  totalSearchVolume
    ? Math.round((100 * usedKeywordsSearchVolume) / totalSearchVolume)
    : 0

export const scoreDetailsDefinitions = {
  [PRODUCT_TITLE]: {
    title: t(
      'listing_builder_v2:manage.ScorePanel.ProductTitle.title',
      'Product Title'
    ),
    guidelines: ({ lowerBound, upperBound }) => [
      {
        description: `${lowerBound}-${upperBound} ${t(
          'generic:characters',
          'characters'
        )}`,
        passes: ({ productTitle }) => {
          return (
            productTitle?.length >= lowerBound &&
            productTitle?.length <= upperBound
          )
        },
        currentValue: ({ productTitle }) => {
          return productTitle?.length
        }
      }
    ],
    score: ({ productTitle }, { lowerBound, upperBound }) => {
      return gradeProductTitle(productTitle, { lowerBound, upperBound })
    }
  },
  [FEATURES]: {
    title: t(
      'listing_builder_v2:manage.ScorePanel.Features.title',
      'Product Features'
    ),
    guidelines: ({ lowerBound, upperBound }) => {
      return [
        {
          description: t(
            'listing_builder_v2:manage.ScorePanel.Features.guidelines.0',
            'Minimum 5 features'
          ),
          passes: ({ features }) => {
            return features?.filter(feature => feature?.length > 0).length >= 5
          },
          currentValue: ({ features }) => {
            return features?.filter(feature => feature?.length > 0).length
          }
        },
        {
          description: t(
            'listing_builder_v2:manage.ScorePanel.Features.guidelines.1',
            '{{features}} features with {{lowerBound}}-{{upperBound}} characters',
            {
              features: EXPECTED_FEATURES,
              lowerBound,
              upperBound
            }
          ),
          passes: ({ features }) => {
            const { featuresWithinBounds } = calcProductFeatures(features, {
              lowerBound,
              upperBound
            })
            return featuresWithinBounds >= EXPECTED_FEATURES
          },
          currentValue: ({ features }) => {
            const { featuresWithinBounds } = calcProductFeatures(features, {
              lowerBound,
              upperBound
            })
            return featuresWithinBounds
          }
        }
      ]
    },
    score: ({ features }, bounds) => {
      return gradeProductFeatures(features, bounds)
    }
  },
  [DESCRIPTION]: {
    title: t(
      'listing_builder_v2:manage.ScorePanel.Description.title',
      'Product Description'
    ),
    guidelines: ({ lowerBound, upperBound }) => [
      {
        description: `${lowerBound}-${upperBound} ${t(
          'listing_builder_v2:manage.ScorePanel.ProductDescription.guidelines.0',
          'characters or A+ content enabled'
        )}`,
        passes: ({ description, enhancedContent }) => {
          return (
            enhancedContent ||
            (description?.length >= lowerBound &&
              description?.length <= upperBound)
          )
        },
        currentValue: ({ description }) => {
          return description?.length
        }
      }
    ],
    score: (args, bounds) => {
      return gradeProductDescription(args, bounds)
    }
  },
  [SEARCH_TERMS]: {
    title: t(
      'listing_builder_v2:manage.ScorePanel.BackendSearchTerms.title',
      'Backend Search Terms'
    ),
    guidelines: ({ lowerBound, upperBound }) => [
      {
        description: `${lowerBound}-${upperBound} ${t(
          'generic:characters',
          'characters'
        )}`,
        passes: ({ searchTerms }) => {
          return (
            searchTerms?.length >= lowerBound &&
            searchTerms?.length <= upperBound
          )
        },
        currentValue: ({ searchTerms }) => {
          return searchTerms?.length
        }
      }
    ],
    score: ({ searchTerms }, bounds) => {
      return gradeSearchTerms(searchTerms, bounds)
    }
  },
  [ADDITIONAL_IMAGES]: {
    title: t(
      'listing_builder_v2:manage.ScorePanel.ProductImages.title',
      'Product Images'
    ),
    guidelines: () => [
      {
        description: t(
          'listing_builder_v2:manage.ScorePanel.ProductImages.guidelines.0',
          '7-9 Images'
        ),
        passes: ({ images }) => {
          const { imagesCount } = calcAdditionalImages(images)
          return imagesCount >= EXPECTED_IMAGES && imagesCount <= MAX_IMAGES
        },
        currentValue: ({ images }) => {
          const { imagesCount } = calcAdditionalImages(images)
          return imagesCount
        }
      },
      {
        description: t(
          'listing_builder_v2:manage.ScorePanel.ProductImages.guidelines.1',
          'All images have a high resolution (1000px or more per side)'
        ),
        passes: ({ images }) => {
          const { imagesCount, highResImagesCount } = calcAdditionalImages(
            images
          )
          return imagesCount > 0 && highResImagesCount === imagesCount
        },
        currentValue: ({ images }) => {
          const { highResImagesCount } = calcAdditionalImages(images)
          return highResImagesCount
        }
      }
    ],
    score: ({ images = [] }) => gradeAdditionalImages(images)
  },
  [KEYWORDS_USED]: {
    title: t(
      'listing_builder_v2:manage.ScorePanel.keywords_used.label',
      'Keywords Used'
    ),
    guidelines: () => [
      {
        description: t(
          'listing_builder_v2:manage.ScorePanel.keywords_used.description',
          '70% of search volume generated by used keywords'
        ),
        passes: args =>
          searchVolumeUsedPercentage(args) >= SEARCH_VOLUME_USED_THRESHOLD,
        currentValue: args =>
          args.isLoading ? null : `${searchVolumeUsedPercentage(args)}%`
      }
    ],
    score: args =>
      searchVolumeUsedPercentage(args) >= SEARCH_VOLUME_USED_THRESHOLD ? 1 : 0
  }
}

export const calculateScoreDetails = (def, args, userLimit) => {
  const definition = scoreDetailsDefinitions[def]

  const guidelines = definition.guidelines(userLimit).map(guidelineDef => {
    const passes = guidelineDef.passes(args)
    const currentValue = guidelineDef.currentValue(args)
    const { description: descriptionGuideline } = guidelineDef

    return {
      passes,
      currentValue,
      description: descriptionGuideline
    }
  })

  const score = definition.score ? definition.score(args, userLimit) : 0

  return {
    title: definition.title,
    guidelines,
    passes: guidelines.every(guideline => guideline.passes),
    score
  }
}

const specialRegexCharacters = /[.*+?^${}()|[\]\\]/g

// used to identify if a string is a special regex character
function isSpecialRegexCharacter(string) {
  return /^[.*+?^${}()|[\]\\]$/.test(string)
}

function escapeRegExp(string) {
  return string.replace(specialRegexCharacters, '\\$&')
}

const getRegexPattern = (keywordName, escapedName) => {
  if (asianIdeographRegex.test(keywordName)) {
    return escapedName
  }

  if (isSpecialRegexCharacter(keywordName)) {
    return `(?<=^|\\W)${escapedName}(?=$|\\W)`
  }

  return `\\b${escapedName}\\b`
}

const findKeywordsInText = (text, keywords, globalUsedKeywordIds) => {
  const usedKeywords = []
  if (!text || !keywords?.length || typeof text !== 'string') {
    return usedKeywords
  }

  keywords.forEach(keyword => {
    if (!keyword.name || !text) {
      return
    }

    const escapedName = escapeRegExp(lowercase(keyword.name).trim())

    const regexPattern = getRegexPattern(keyword.name, escapedName)
    const usedCount = (
      text.toLowerCase().match(new RegExp(regexPattern, 'g')) || []
    ).length
    if (usedCount) {
      usedKeywords.push({ keyword, usedCount })
      globalUsedKeywordIds.add(keyword.id)
    }
  })

  usedKeywords.sort(sortBy('usedCount', 'desc'))
  return usedKeywords
}

export const findUsedKeywords = (editorFields, keywords) => {
  const globalUsedKeywordIds = new Set()
  const usedKeywordsByField = Object.keys(editorFields).reduce(
    (accumulator, fieldKey) => {
      accumulator[fieldKey] = findKeywordsInText(
        String(editorFields[fieldKey]).replace(/<img.*?>/g, ''), // for description fields dont include words like "img" or "class" when checking for keywords
        keywords,
        globalUsedKeywordIds
      )
      return accumulator
    },
    {}
  )
  return { globalUsedKeywordIds, usedKeywordsByField }
}

/**
 * Generates a listing builder features object from an array of feature bullets
 * e.g. ['Feature 1', 'Feature 2'] => { feature_one: 'Feature 1', feature_two: 'Feature 2' }
 *
 * @param {Array} featureBullets
 * @returns {Object}
 */
export const generateListingBuilderFeatures = (featureBullets = []) => {
  const MAX_FEATURES = 10
  const features = {}
  const enums = [
    'one',
    'two',
    'three',
    'four',
    'five',
    'six',
    'seven',
    'eight',
    'nine',
    'ten'
  ]

  featureBullets.forEach((bullet, index) => {
    if (index > MAX_FEATURES - 1) {
      return
    }

    features[`feature_${enums[index]}`] = bullet
  })

  return features
}

/**
 * Returns true if the description contains enhanced content (img, script, a tags)
 *
 * @param {String} description
 * @returns {Boolean}
 */
export const isEnhancedContent = description => {
  if (!description) {
    return false
  }

  const targetTags = ['<img', '<script', '<a']

  return targetTags.some(tag => description.includes(tag))
}

export const featureKey = key => `feature_${key}`

export const findLastFeatureIndex = listing => {
  for (
    let i = FEATURE_KEYS.length - 1;
    i > DEFAULT_LAST_FEATURE_INDEX;
    i -= 1
  ) {
    const fieldKey = featureKey(FEATURE_KEYS[i])
    if (listing[fieldKey]) {
      return i
    }
  }
  return DEFAULT_LAST_FEATURE_INDEX
}

/**
 * Function to find words that appear more than twice (2 or more).
 * Plural words are counted as redundant. Works best with English text.
 * Common articles and preopositions are not counted as redundant.
 *
 * @param {String} text - Paragraph of text
 * @returns {Array} - List of redundant words
 */
export const findRedundantWords = text => {
  if (!text) {
    return []
  }

  const words = text
    .split(/\s+/)
    .map(word => word.toLowerCase().replace(/[^\p{L}'-]/gu, ''))
    .filter(word => word && word !== '-') // Clean punctuation, empty strings and hyphens

  const normalizedMap = new Map()

  words.forEach(word => {
    if (EXCLUDE_ARTICLES_AND_PREPOSITIONS.includes(word)) {
      return
    }

    const singular = pluralize.singular(word)
    const isPlural = word !== singular
    const hasExistingEntry = normalizedMap.has(singular)

    if (!hasExistingEntry) {
      normalizedMap.set(singular, {
        count: 0,
        allPlural: true,
        pluralForm: word
      })
    }

    const entry = normalizedMap.get(singular)
    entry.count += 1

    // If we encounter a singular form, set `allPlural` to false
    if (!isPlural) {
      entry.allPlural = false
    }

    // If the current word is plural, store it as the pluralForm (just in case we need it later)
    if (isPlural) {
      entry.pluralForm = word
    }
  })

  const redundantWords = []

  normalizedMap.forEach(({ count, allPlural, pluralForm }, singular) => {
    if (count > 2) {
      redundantWords.push(allPlural ? pluralForm : singular)
    }
  })

  return redundantWords
}
